import { signIn } from 'next-auth/react';
import { GoogleLogo } from '@/icons/GoogleLogo';
import { cn } from 'ui/cn';

export const GoogleLoginButton = ({ className, text }: { className?: string; text?: string }) => {
  const handleLogInClick = () => {
    void signIn('google');
  };

  return (
    <button
      className={cn(
        'font-roboto flex items-center hover:shadow sm:py-[10px] sm:px-[20px] sm:border rounded-[2px] gap-[10px]',
        className
      )}
      onClick={handleLogInClick}>
      <GoogleLogo className="hidden sm:inline" />
      <span className="text-xs leading-snug sm:text-sm sm:leading-none">{text ?? 'Log in'}</span>
    </button>
  );
};
