import { GetServerSideProps } from 'next';
import { getServerSession } from 'next-auth';
import { authOptions } from '@/pages/api/auth/[...nextauth]';
import { GoogleLoginButton } from '@/components/GoogleLoginButton';

export default function HomePage() {
  return (
    <div className="bg-[#111317] text-white z-50 h-screen min-h-[830px] flex flex-col">
      <div className="absolute h-[365px] top-[-320px] left-0 right-0 md:left-[5%] md:right-[5%] rounded-[50%] bg-primary-500 opacity-40 blur-[143px]" />

      <header className="px-[48px] pt-[58px]">
        <svg xmlns="http://www.w3.org/2000/svg" width="126" height="36" viewBox="0 0 126 36" fill="none">
          <g clipPath="url(#clip0_303_9118)">
            <path
              d="M25.7375 26.4111C24.2562 26.4111 22.9421 26.1607 21.7834 25.6661C20.6306 25.1716 19.6988 24.4633 18.988 23.5534L21.6042 21.2881C22.2134 21.972 22.9003 22.4666 23.6709 22.7658C24.4414 23.065 25.2238 23.2176 26.0123 23.2176C26.6394 23.2176 27.1471 23.0711 27.5294 22.7841C27.9117 22.491 28.1088 22.088 28.1088 21.569C28.1088 21.111 27.9236 20.7508 27.5593 20.4821C27.1949 20.2318 26.3229 19.9203 24.9431 19.5479C22.8944 19.0105 21.4489 18.2717 20.5948 17.3436C19.8422 16.5132 19.4718 15.4874 19.4718 14.2661C19.4718 13.2953 19.7526 12.4404 20.32 11.7016C20.8874 10.9628 21.64 10.3888 22.5718 9.97969C23.5036 9.56448 24.519 9.35687 25.6121 9.35687C26.8664 9.35687 28.055 9.5828 29.166 10.0408C30.2829 10.4987 31.2147 11.1215 31.9613 11.9031L29.7095 14.4188C29.1421 13.8815 28.4851 13.4296 27.7504 13.0694C27.0097 12.7091 26.3229 12.5259 25.7017 12.5259C24.2443 12.5259 23.5156 13.0449 23.5156 14.0769C23.5335 14.5775 23.7604 14.9683 24.1845 15.2553C24.5907 15.5484 25.5105 15.8904 26.95 16.2811C28.8733 16.8002 30.2352 17.484 31.0236 18.3328C31.7105 19.0594 32.0569 20.0242 32.0569 21.2209C32.0569 22.2162 31.7762 23.1077 31.2207 23.8954C30.6652 24.6831 29.9066 25.2998 28.9569 25.7455C28.0072 26.1913 26.9321 26.4111 25.7375 26.4111Z"
              fill="white"
            />
            <path
              d="M35.0135 26.1058V9.69886H39.0274L39.0871 12.0314C39.6546 11.2437 40.3653 10.6087 41.2135 10.1202C42.0676 9.63169 42.9576 9.38745 43.8894 9.38745C45.0422 9.38745 46.0098 9.64391 46.7803 10.1507C47.5508 10.6575 48.178 11.4635 48.6678 12.5626C49.2531 11.5917 50.0535 10.8163 51.051 10.2484C52.0545 9.68054 53.0938 9.39356 54.1689 9.39356C57.8363 9.39356 59.67 11.6528 59.67 16.1652V26.1058H55.5068V16.4766C55.5068 14.199 54.593 13.0572 52.7712 13.0572C51.7976 13.0572 50.9913 13.3686 50.3522 13.9914C49.7131 14.6142 49.3965 15.3897 49.3965 16.3239V26.1119H45.2333V16.4155C45.2333 15.3409 45.0004 14.5104 44.5345 13.9304C44.0686 13.3503 43.3996 13.0633 42.5276 13.0633C41.5779 13.0633 40.7775 13.3747 40.1443 13.9975C39.5052 14.6203 39.1887 15.3775 39.1887 16.2629V26.1119H35.0255L35.0135 26.1058Z"
              fill="white"
            />
            <path
              d="M69.7285 26.4111C68.3906 26.4111 67.184 26.0386 66.1089 25.2937C65.0338 24.5487 64.1796 23.5351 63.5405 22.2468C62.9014 20.9645 62.5848 19.5051 62.5848 17.8687C62.5848 16.2323 62.9014 14.718 63.5405 13.4418C64.1796 12.1657 65.0517 11.1704 66.1567 10.4438C67.2617 9.71713 68.51 9.35687 69.9077 9.35687C71.0426 9.35687 72.0341 9.5889 72.8882 10.053C73.7364 10.517 74.4173 11.1276 74.925 11.8726V9.69881H79.0583V26.1058H74.8952V23.9015C74.3277 24.6281 73.593 25.2265 72.6911 25.7028C71.7892 26.179 70.8037 26.4172 69.7285 26.4172V26.4111ZM70.8514 22.7169C72.0879 22.7169 73.0913 22.2712 73.8618 21.3797C74.6323 20.4882 75.0146 19.322 75.0146 17.8687C75.0146 16.4155 74.6323 15.2492 73.8618 14.3577C73.0913 13.4662 72.0879 13.0205 70.8514 13.0205C69.615 13.0205 68.6474 13.4662 67.8888 14.3577C67.1303 15.2492 66.748 16.4155 66.748 17.8687C66.748 19.322 67.1303 20.4882 67.8888 21.3797C68.6474 22.2712 69.6389 22.7169 70.8514 22.7169Z"
              fill="white"
            />
            <path
              d="M83.0542 26.1058V9.69881H87.068L87.1278 12.4038C87.6534 11.5123 88.3463 10.7796 89.2123 10.2117C90.0724 9.64386 90.9803 9.35687 91.936 9.35687C92.3004 9.35687 92.6408 9.3813 92.9514 9.43625C93.262 9.49121 94.267 9.83358 94.488 9.91296L92.6349 14.2967C92.4139 14.1745 92.1272 14.0707 91.7807 13.9853C91.4343 13.9059 91.0938 13.8631 90.7474 13.8631C89.7559 13.8631 88.9197 14.2112 88.2387 14.9073C87.5578 15.6034 87.2174 16.4765 87.2174 17.5329V26.1058H83.0542Z"
              fill="white"
            />
            <path
              d="M99.0439 26.1058V13.5212H96.0037V9.69882H99.0439V5.126H103.177V9.69882H106.552V13.5212H103.177V26.1058H99.0439Z"
              fill="white"
            />
            <path
              d="M111.513 34.8556L114.894 26.1733L107.805 9.69882H112.518L116.591 19.5174C116.776 19.9509 116.956 20.4577 117.141 21.0378C117.218 20.8118 117.296 20.5859 117.368 20.3661C117.439 20.1463 117.517 19.9143 117.595 19.67L121.089 9.69272H125.861L115.618 34.929L111.513 34.8556Z"
              fill="white"
            />
            <path d="M2.84486 30.3923L14.9145 0.716827H18.9881L6.90648 30.3923H2.84486Z" fill="#878689" />
          </g>
          <defs>
            <clipPath id="clip0_303_9118">
              <rect width="126" height="34.5664" fill="white" transform="translate(0 0.716827)" />
            </clipPath>
          </defs>
        </svg>
      </header>

      <main className="flex-1 flex flex-col items-center justify-center text-center gap-[24px]">
        <h1
          className="inline-block text-[44px] leading-[110%] font-[600]"
          style={{
            background: 'linear-gradient(267deg, #1A73E8 -5.79%, #FFF 48.93%, #FF1F9E 114.98%)',
            backgroundClip: 'text',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          }}>
          Plan your best day,
          <br />
          every day.
        </h1>
        <GoogleLoginButton className="bg-white text-black" text="Log in with Google" />
        <p className="text-[14px]">Conversational commands to manage it all.</p>
      </main>
      <footer className="py-[48px] text-[12px] text-secondary-300 text-center">
        Smarty needs a few permissions to work -{' '}
        <a
          className="text-white"
          href="https://help.smarty.ai/everything-else/understanding-permissions"
          rel="nofollow">
          learn more
        </a>
        .
      </footer>
    </div>
  );
}

export const getServerSideProps: GetServerSideProps = async (context) => {
  const session = await getServerSession(context.req, context.res, authOptions());
  if (session?.user?.isNewUser) {
    return {
      props: {},
      redirect: {
        destination: '/onboarding',
      },
    };
  } else if (session?.user) {
    return {
      redirect: {
        destination: '/calendar',
        permanent: false,
      },
    };
  }
  return {
    props: {},
  };
};
